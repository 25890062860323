.main_video_container h1 {
  color: #000;
  text-align: center;
  font-size: 45px;
  font-family: 'PoppinsRegular';
  padding: 0px;
  margin: 0px;
  margin-top: 100px;
  line-height: 40px;
  /* 100% */
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.main_video_container .red {
  color: #be0000;
  margin-top: 0px;
  margin-bottom: 20px;
}

.main_video_container p {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-family: 'PoppinsLight';
  line-height: 30px;
  letter-spacing: 0.32px;
  margin-bottom: 40px;
}

.main_video_container .video_container {
  position: relative;
}

.main_video_container .video_container video {
  width: 100%;
  border-radius: 15px;
}

.main_video_container
  .video_container
  .video_overlay_inner
  .playpause_btn_container {
  position: absolute;
  z-index: 9999;
  top: 45%;
  left: 45%;
  background: rgba(225, 46, 42, 0.5);
  width: 116px;
  height: 116px;
  border-radius: 58px;
}

.main_video_container
  .video_container
  .video_overlay_inner
  .playpause_btn_container
  button {
  background: rgba(225, 46, 42, 1);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-left: 7px;
  margin-top: 7px;
  padding-left: 41px;
}

.play {
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-left: 26px solid #fff;
  border-bottom: 14px solid transparent;
}

.pause {
  width: 8px;
  height: 32px;
  border-right: 8px solid #fff;
  border-left: 8px solid #fff;
}

.main_video_container .hidden {
  display: none;
}

.main_video_container .extend {
  margin-bottom: 66px;
}

video::-webkit-media-controls {
  display: none;
}

@media screen and (max-width: 1024px) {
  .main_video_container h1 {
    font-size: 25px;
    margin-top: 50px;
    line-height: 25px;
    letter-spacing: 0px;
  }

  .main_video_container p {
    font-size: 16px;
    width: 100%;
    height: 100%;
    line-height: 16px;
    /* 187.5% */
    letter-spacing: 0.33px;
    margin-bottom: 40px;
  }

  .main_video_container .video_container video {
    width: 100%;
    height: 100%;
  }

  .main_video_container
    .video_container
    .video_overlay_inner
    .playpause_btn_container {
    background: rgba(225, 46, 42, 0.5);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    top: 40%;
    left: 42%;
  }

  .main_video_container
    .video_container
    .video_overlay_inner
    .playpause_btn_container
    button {
    background: rgba(225, 46, 42, 1);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    margin-left: 5px;
    margin-top: 5px;
    padding-left: 15px;
  }

  .play {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 16px solid #fff;
    border-bottom: 8px solid transparent;
  }

  .pause {
    width: 5px;
    height: 20px;
    border-right: 5px solid #fff;
    border-left: 5px solid #fff;
  }

  .main_video_container .hidden {
    display: none;
  }

  .main_video_container .extend {
    margin-bottom: 50px;
  }

  video::-webkit-media-controls-play-button {
    display: none;
  }

  video::-webkit-media-controls {
    display: none;
  }
}
