.projects_main_container {
  margin-top: 120px;
  margin-bottom: 100px;
}

.projects_main_container .header_box h1 {
  text-align: center;
  font-size: 45px;
  font-family: 'PoppinsRegular';
  line-height: 40px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.projects_main_container .header_box h1 span {
  color: #e12e2a;
}

.projects_main_container .header_box p {
  color: #000;
  text-align: center;
  font-family: "PoppinsLight";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.32px;
}

.projects_main_container .projects_box {
  position: relative;
  margin-top: 50px;
}

.projects_main_container .projects_box .project_inner_box {
  position: relative;
}

.projects_main_container .projects_box .project_inner_box .project_img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin-bottom: 50px;
}

.projects_main_container .projects_box .project_inner_box .project_text {
  position: absolute;
  left: 0;
  bottom: 70px;
  font-size: 24px;
  font-family: "PoppinsBold";
  line-height: 30px;
  color: #fff;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .projects_main_container {
    margin-bottom: 0;
  }
  .projects_main_container .header_box h1 {
    font-size: 24px;
    line-height: 25px;
    letter-spacing: 0px;
  }

  .projects_main_container .header_box p {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.33px;
  }
}
