.airport_main_container {
  margin-top: 120px;
  margin-bottom: 100px;
}

.airport_main_container .header_box h1 {
  text-align: center;
  font-size: 45px;
  font-family: 'PoppinsRegular';
  line-height: 40px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.airport_main_container .header_box h1 span {
  color: #e12e2a;
}

.airport_main_container .header_box p {
  color: #000;
  text-align: center;
  font-family: "PoppinsLight";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.32px;
}

.airport_main_container .airports_box {
  position: relative;
  margin-top: 50px;
}

.airport_main_container .airports_box .airport_inner_box {
  position: relative;
}

.airport_main_container .airports_box .airport_inner_box .airport_img1 {
  width: 93%;
  border-radius: 15px;
  margin-bottom: 20px;
}

.airport_main_container .airports_box .airport_inner_box .airport_img {
  /* width: 100%; */
  /* height: 100%; */
  border-radius: 15px;
  margin-bottom: 20px;
}

.airport_main_container .airports_box .airport_inner_box .airport_text_box {
  position: absolute;
  left: 20px;
  bottom: 40px;
}

.airport_main_container
  .airports_box
  .airport_inner_box
  .airport_text_box
  .airport_text1 {
  font-size: 20px;
  font-family: "PoppinsBold";
  color: #fff;
  line-height: 24px;
  margin: 0;
}

.airport_main_container
  .airports_box
  .airport_inner_box
  .airport_text_box
  .airport_text2 {
  font-size: 16px;
  font-family: "PoppinsLight";
  color: #fff;
  line-height: 24px;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .airport_main_container .header_box h1 {
    font-size: 24px;
    line-height: 25px;
    letter-spacing: 0px;
  }

  .airport_main_container .header_box p {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.33px;
  }

  .airport_main_container .airports_box .airport_inner_box .airport_img1 {
    width: 100%;
    height: 100%;
  }

  .airport_main_container .airports_box .airport_inner_box .airport_img {
    width: 100%;
    height: 100%;
  }

  .airport_main_container
    .airports_box
    .airport_inner_box
    .airport_text_box
    .airport_text1 {
    font-size: 18px;
    line-height: 22px;
  }
}
