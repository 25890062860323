.main_contact_body {
  margin-top: 40px;
  margin-bottom: 40px;
}

.main_contact_body .heading {
  text-align: center;
  font-size: 40px;
  font-family: 'PoppinsRegular';
  line-height: 40px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.main_contact_body .heading span {
  font-size: 40px;
  font-family: 'PoppinsRegular';
  line-height: 40px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: var(--Red-color, #e12e2a);
}

.main_contact_body .description {
  text-align: center;
  font-size: 16px;
  font-family: 'PoppinsLight';
  line-height: 30px;
  letter-spacing: 0.32px;
}

.main_contact_body .contact_form_container {
  margin-top: 60px;
  margin-bottom: 60px;
}

.main_contact_body .contact_form .contact_input {
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 7px;
  margin-bottom: 20px;
  color: #000;
  font-weight: 400;
}

.main_contact_body .contact_form .contact_input::placeholder {
  color: #000;
  font-weight: 400;
}

.main_contact_body .contact_form .contact_message {
  width: 100%;
  background-color: #fff;
  padding: 15px;
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 7px;
  margin-bottom: 20px;
  color: #000;
  font-weight: 400;
}

.main_contact_body .contact_form .contact_message::placeholder {
  color: #000;
  font-weight: 400;
}

.main_contact_body .contact_form .contact_form_button {
  text-align: center;
  width: 167px;
  height: 54px;
  border-radius: 40px;
  background: #000;
  margin: 10px 0px;
  color: #fff;
  border: 1px solid #000;
  transition: all 0.5s;
}

.main_contact_body .contact_form .contact_form_button:hover {
  background-color: #e12e2a;
  border: 1px solid #e12e2a;
}

.main_contact_container .main_contact_body .contact_us_map {
  border-radius: 20px;
}

.main_contact_container .contact_details_us {
  position: relative;
  background-color: #e12e2a;
  border-radius: 30px;
  padding: 30px;
  margin-left: 12px;
  margin-bottom: 120px;
}

.main_contact_container .contact_details_us .contact_info_box {
  width: 40%;
}

.main_contact_container .contact_details_us .contact_info_box .detail_sec_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_contact_container
  .contact_details_us
  .contact_info_box
  .detail_sec_1
  .detail_info_box
  .title {
  color: #000;
  font-size: 18px;
  font-family: 'PoppinsBold';
  line-height: 32px;
  margin-bottom: 0;
}

.main_contact_container
  .contact_details_us
  .contact_info_box
  .detail_sec_1
  .detail_info_box
  .value {
  color: #fff;
  font-size: 18px;
  font-family: 'PoppinsBold';
  line-height: 32px;
}

.main_contact_container
  .contact_details_us
  .contact_info_box
  .detail_sec_1
  .detail_info_box_2
  .title {
  color: #000;
  font-size: 18px;
  font-family: 'PoppinsBold';
  line-height: 32px;
  margin-bottom: 0;
}

.main_contact_container
  .contact_details_us
  .contact_info_box
  .detail_sec_1
  .detail_info_box_2
  .value {
  color: #fff;
  font-size: 18px;
  font-family: 'PoppinsBold';
  line-height: 32px;
}

.main_contact_container
  .contact_details_us
  .contact_info_box
  .detail_sec_2
  .detail_info_box
  .title {
  color: #000;
  font-size: 18px;
  font-family: 'PoppinsBold';
  line-height: 32px;
  margin-bottom: 0;
}

.main_contact_container
  .contact_details_us
  .contact_info_box
  .detail_sec_2
  .detail_info_box
  .value {
  color: #fff;
  font-size: 18px;
  font-family: 'PoppinsBold';
  line-height: 32px;
}

.main_contact_container .contact_details_us .contact_car_image {
  position: absolute;
  top: -50px;
  right: 0;
}

@media screen and (max-device-width: 1024px) {
  .main_contact_container .main_contact_body .contact_us_map {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .main_contact_container .contact_details_us .contact_info_box {
    width: 100%;
  }

  .main_contact_container .contact_details_us .contact_info_box .detail_sec_1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .main_contact_container
    .contact_details_us
    .contact_info_box
    .detail_sec_1
    .detail_info_box_2 {
    margin-left: 0;
  }

  .main_contact_container .contact_details_us {
    margin-bottom: 40px;
    margin-right: 12px;
  }

  .main_contact_container .contact_details_us .contact_car_image {
    display: none;
  }
}
