.banner-container {
  background-color: #c32027;
  padding: 80px;
  text-align: center;
}

.banner-container .inner-container .heading {
  font-size: 40px;
  font-family: "PoppinsBold";
  line-height: 44px;
  color: #fff;
  text-transform: uppercase;
}

.banner-container .inner-container .subheading {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #fff;
  margin-top: 30px;
}

@media screen and (max-width: 1024px) {
  .banner-container {
    padding: 80px 20px 20px 20px;
  }

  .banner-container .inner-container .heading {
    font-size: 16px;
    line-height: 20px;
  }

  .banner-container .inner-container .subheading {
    font-size: 12px;
    line-height: 22px;
  }
}
