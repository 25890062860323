.payment-main-container {
  margin-top: 100px;
}

.payment-main-container .back-btn {
  cursor: pointer;
  margin-top: 30px;
  width: 132px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.payment-main-container .back-btn p {
  font-size: 14px;
  font-family: 'PoppinsLight';
  line-height: 20px;
  color: #000;
}

.payment-main-container .mainheading {
  font-size: 32px;
  font-family: "PoppinsRegular";
  line-height: 40px;
  color: #000;
}

.payment-main-container .maintext {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #000;
}

/* Left Container */
.payment-main-container .first-div {
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.payment-main-container .first-div .date-time {
  font-size: 20px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #696969;
}

.payment-main-container .first-div .destination-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.payment-main-container .first-div .destination-details .text {
  font-size: 20px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #000;
}

.payment-main-container .first-div .destination-details .arrow-ico {
  padding-left: 20px;
}

.payment-main-container .first-div .destination-details .text2 {
  font-size: 20px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #000;
  padding-left: 20px;
}

.payment-main-container .second-div {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.payment-main-container .second-div .car-info .type {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #7e7e7e;
}

.payment-main-container .second-div .car-info .category {
  font-size: 16px;
  font-family: "PoppinsBold";
  line-height: 24px;
  color: #000;
}

.payment-main-container .second-div .passenger-info .text1 {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #7e7e7e;
}

.payment-main-container .second-div .passenger-info .text2 {
  font-size: 16px;
  font-family: "PoppinsBold";
  line-height: 24px;
  color: #000;
}

/* Third Div in Left Container */
.payment-main-container .third-div {
  margin-top: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.payment-main-container .left-container h2 {
  font-size: 20px;
  font-family: "PoppinsRegular";
  line-height: 27px;
  color: #000;
  margin-bottom: 40px;
}

/* Input Fields */
.payment-main-container .left-container .input-fields label {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #000;
  padding-bottom: 5px;
}

.payment-main-container .left-container .input-fields input {
  padding: 10px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  width: 100%;
  color: #333;
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  margin-bottom: 25px;
}

.payment-main-container .left-container .input-fields textarea {
  padding: 10px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  width: 100%;
  color: #333;
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
}

.payment-main-container .left-container .input-fields .slogn {
  font-size: 10px;
  font-family: "PoppinsLight";
  line-height: 16px;
  color: #039c00;
}

.payment-main-container .left-container .input-fields input::placeholder {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #7e7e7e;
}

.payment-main-container .mobile {
  width: 100%;
  border-radius: 0px 4px 4px 0px !important;
}

.payment-main-container select {
  width: 30%;
  padding: 10px 20px;
  height: 47px;
  border: 1px solid #ff8383;
  border-radius: 4px 0px 0px 4px;
  border-right: none;
  margin: 0px;
  background-color: #ff8383;
  color: #fff;
}

.payment-main-container .mobile-input {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

/* Fourth Div (Flight Information) */
.payment-main-container .flight-div {
  margin-top: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.payment-main-container .left-container .flight-div h2 {
  font-size: 20px;
  font-family: "PoppinsRegular";
  line-height: 27px;
  color: #000;
}

.payment-main-container .flight-div p {
  font-size: 16px;
  width: 100%;
  color: #fff;
  background-color: #ff8383;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
}

/* Fifth Div (Driver Information) */
.payment-main-container .driver-div {
  margin-top: 20px;
  margin-bottom: 80px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

/* Right Container */
.payment-main-container .right-container {
  background-color: rgb(243, 243, 243);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

/* Sixth Div (Secure Payment) */
.payment-main-container .secure-payment-div {
  margin-bottom: 30px;
}

.payment-main-container .secure-payment-div h2 {
  font-size: 24px;
  font-family: "PoppinsLight";
  line-height: 27px;
  color: #000;
}

.payment-main-container .secure-payment-div input {
  width: 20px;
  height: 20px;
}

.payment_inner_main_container .secure-payment-div label {
  margin-left: 10px;
  font-size: 16px;
  font-family: "PoppinsRegular";
  color: #e12e2a;
}

.payment_inner_main_container .secure-payment-div .check {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.payment-main-container .secure-payment-div p {
  padding: 10px 0px;
  font-size: 16px;
  font-family: "PoppinsLight";
  color: #333;
}

.payment-main-container .secure-payment-div p span {
  padding: 10px 0px;
  font-size: 16px;
  font-family: "PoppinsBold";
  color: #333;
}

.payment-main-container .secure-payment-div button {
  padding: 10px 20px;
  background-color: #e12e2a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-family: "PoppinsBold";
  line-height: 26px;
}

/* Seventh Div (Trusted Since) */
.payment-main-container .trusted-since-div {
  text-align: justify;
}

.payment-main-container .trusted-since-div h2 {
  font-size: 16px;
  font-family: "PoppinsRegular";
  line-height: 24px;
  color: #e12e2a;
}

.payment-main-container .trusted-since-div p {
  font-size: 14px;
  font-family: "PoppinsLight";
  color: #a8a7a7;
}

@media screen and (max-width: 1024px) {
  .payment-main-container {
    margin-bottom: 80px;
  }

  .payment-main-container .mainheading {
    font-size: 14px;
    font-family: "PoppinsBold";
  }
  .payment-main-container .maintext {
    font-size: 12px;
  }

  /* Left Container */
  .payment-main-container .first-div {
    padding: 10px;
  }
  .payment-main-container .first-div .date-time {
    font-size: 12px;
  }

  .payment-main-container .first-div .destination-details .text {
    font-size: 12px;
  }

  .payment-main-container .first-div .destination-details .text2 {
    font-size: 12px;
  }

  .payment-main-container .car-image {
    width: 160px;
    height: auto;
    margin-bottom: 20px;
  }

  .payment-main-container .second-div .car-info .type {
    font-size: 12px;
  }

  .payment-main-container .second-div .passenger-info .text1 {
    font-size: 12px;
  }

  .payment-main-container .second-div .car-info .category {
    font-size: 12px;
    font-family: "PoppinsBold";
  }

  .payment-main-container .second-div .passenger-info .text2 {
    font-size: 12px;
    font-family: "PoppinsBold";
  }

  .payment-main-container .second-div .car-mobile-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* Third Div in Left Container */
  .payment-main-container .left-container h2 {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .payment-main-container .left-container .flight-div h2 {
    font-size: 12px;
    margin-bottom: 20px;
  }

  .payment-main-container .flight-div p {
    font-size: 12px;
    padding: 10px;
  }
  /* Input Fields */
    .payment-main-container .left-container .input-fields label {
      font-size: 12px;
    }
  .payment-main-container select {
    width: 40%;
  }

  /* Right Container */
  /* Sixth Div (Secure Payment) */
  .payment-main-container .secure-payment-div h2 img {
    width: 15px;
    height: 12px;
  }
  .payment-main-container .secure-payment-div h2 {
    font-size: 14px;
  }
  .payment_inner_main_container .secure-payment-div label {
    font-size: 12px;
  }
  .payment-main-container .secure-payment-div p {
    font-size: 12px;
  }

  .payment-main-container .secure-payment-div p span {
    font-size: 12px;
  }
  .payment-main-container .secure-payment-div button {
    font-size: 12px;
    padding: 10px;
  }
  .payment-main-container .trusted-since-div h2 {
    font-size: 12px;
  }
  .payment-main-container .trusted-since-div p {
    font-size: 12px;
  }
}
