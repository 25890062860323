.cars-cards-container {
  display: flex;
  justify-content: space-around;
  margin-left: 100px;
  min-height: 800px;
}

.car-card {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 45px;
  margin-bottom: 25px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.car-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.car-image {
  width: 300px;
  object-fit: cover;
}

.car-type {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 10px;
}

.example {
  margin-bottom: 10px;
}

.passengers-price {
  display: flex;
  justify-content: space-between;
}

.button_container {
  width: 100%;
  text-align: right;
}

.book-now {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #e12e2a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  padding: 10px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: "PoppinsRegular";
  line-height: 24px;
}

.cars-cards-container .button_container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.additional-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ff8383;
  border-radius: 5px;
  padding: 15px 25px;
}

.additional-info .pickup {
  text-align: center;
}

.additional-info .pickup img {
  padding-right: 5px;
}

.additional-info .pickup span {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #fff;
}

.additional-info .waiting {
  text-align: center;
}

.additional-info .waiting img {
  padding-right: 5px;
}

.additional-info .waiting span {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #fff;
}

.additional-info .delays {
  text-align: center;
}

.additional-info .delays img {
  padding-right: 5px;
}

.additional-info .delays span {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #fff;
}

.toggle-button {
  text-align: center;
  margin-top: 15px;
}

.toggle-button button {
  background-color: #fff;
  color: #000;
  padding: 16px 26px;
  border: solid #000;
  border-radius: 26px;
  cursor: pointer;
  border-width: 2px;
}

.toggle-button button:hover {
  background-color: #e12e2a;
  color: #fff;
  border: none;
  padding: 17.7px 26px;
}

.cars-cards-container .map-container {
  width: 500px;
  height: 360px;
  margin-right: 100px;
  margin-top: 80px;
}

.cars-cards-container .first-div {
  background-color: #fff;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  max-height: 150px;
  width: 533px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid #ddd;
}

.cars-cards-container .last {
  margin-bottom: 15%;
}

.cars-cards-container .first-div p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
}

.cars-cards-container .first-div p img {
  width: 30px;
  height: 30px;
  margin-bottom: -9px;
}

.cars-cards-container .map-container .map_details {
  width: 100%;
  height: 80%;
  margin-top: 2%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cars-cards-container .map-container .map_details div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-left: 2%;
}

.cars-cards-container .map-container .map_details div img {
  width: 30px;
  height: 33px;
}

@media screen and (max-width: 1024px) {
  .car-image {
    width: 181px;
  }
  .book-now {
    font-size: 12px;
    padding: 8px 20px;
  }
  .additional-info {
    padding: 10px 12px;
    width: 100%;
  }
  .additional-info .pickup {
    padding-right: 3px;
  }
  .additional-info .pickup img {
    width: 12px;
    height: 12px;
  }
  .additional-info .pickup span {
    font-size: 12px;
  }
  .additional-info .waiting {
    padding-right: 3px;
  }
  .additional-info .waiting span {
    font-size: 12px;
  }
  .additional-info .delays img {
    width: 12px;
    height: 12px;
  }
  .additional-info .delays span {
    font-size: 12px;
  }
}
