.Navbar_App_header {
  position: relative;
}

.Navbar_App_header .app_logo {
  position: absolute;
  left: 90px;
  top: 35px;
}

.Navbar_App_header .app_logo img {
  width: 121px;
  height: 121px;
  border-radius: 100%;
}

.desktop_navigation {
  background-color: #f9f9f9;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 100px;
  margin: 0;
}

.desktop_navigation a,
select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(6, 6, 6);
  text-align: center;
  padding: 36px 20px;
  margin: 0px 20px;
  text-decoration: none;
  border: none;
  background-color: inherit;
  cursor: pointer;
  position: relative;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 0px;
  width: 2px;
  background-color: #e12e2a;
  transition: hieght 0.3s ease;
}

.active .underline {
  height: 30%;
}

select {
  padding-right: 20px;
}

.mobile_navbar_app {
  position: relative;
}

.mobile_navbar_app .mobile_bar {
  width: 100%;
  background-color: #fff;
  padding: 20px;
}

.mobile_navbar_app .mobile_bar .app_logo {
  position: absolute;
  left: 10px;
  top: 25px;
}

.mobile_navbar_app .mobile_bar .app_logo img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

.mobile_navbar_app .mobile_bar .nav_menu_options {
  cursor: pointer;
  text-align: right;
}

.mobile_navbar_app .mobile_navigation {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: #fff;
  padding-top: 20px;
}

.mobile_navbar_app .mobile_navigation .mobile_box_header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.mobile_navbar_app .mobile_navigation .mobile_box_header .app_logo {
  padding-left: 20px;
}

.mobile_navbar_app .mobile_navigation .mobile_box_header .app_logo img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

.mobile_navbar_app .mobile_navigation .mobile_box_header .closebtn {
  cursor: pointer;
  font-size: 46px;
  padding-right: 20px;
}

.mobile_navbar_app .mobile_navigation .mobile_box_content {
  padding-left: 20px;
}

.mobile_navbar_app .mobile_navigation .mobile_box_content .mobile_nav_option {
  font-size: 24px;
  font-family: 'PoppinsBold';
  line-height: 34px;
  color: #333;
  text-decoration: none;
}

.mobile_navbar_app .mobile_navigation .mobile_box_content .mobile_nav_option p {
  padding-top: 20px;
}

.mobile_navbar_app .mobile_navigation .mobile_box_content .mobile_nav_option p:hover {
  color: #e12e2a;
}

@media screen and (max-width: 600px) {
  nav {
    background-color: #f9f9f9;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 0px;
    margin: 0;
  }

  nav a,
  select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(6, 6, 6);
    text-align: center;
    padding: 16px 0px;
    margin: 0px 10px;
    text-decoration: none;
    border: none;
    background-color: inherit;
    cursor: pointer;
    position: relative;
    font-size: 10px;
  }

  .underline {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0px;
    width: 2px;
    background-color: #e12e2a;
    transition: hieght 0.3s ease;
  }

  .active .underline {
    height: 30%;
  }

  select {
    padding-right: 0px;
  }
}
