.main_services_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
    height: 100%;
    margin: 100px;
}

.main_services_container .content-container {
    text-align: center;
    width: 1071.165px;
}

.main_services_container .content-container h1 {
    flex-shrink: 0;
}

.main_services_container .content-container h1 .headingpart1 {
    color: #000;
    font-family: 'PoppinsRegular';
    font-size: 45px;
    line-height: 45px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    margin: 10px;
}

.main_services_container .content-container h1 .headingpart2 {
    color: #E12E2A;
    font-family: 'PoppinsRegular';
    font-size: 45px;
    line-height: 45px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    margin: 10px;
}

.main_services_container .content-container p {
    color: #000;
    text-align: center;
    font-family: 'PoppinsLight';
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.32px;
}

.main_services_container .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.main_services_container .grid-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    height: 293px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #EDEDED;
    background: #FFF;
    margin-top: 160px;
    transition: all 0.5s;
}

.main_services_container .grid-item .image_container {
    width: 352px;
    height: 244.962px;
    flex-shrink: 0;
    margin-top: -130px;
    /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
}

.main_services_container .grid-item .text {
    width: 352px;
    height: 244.962px;
    margin-top: 60px;
}

.main_services_container .grid-item .image_container img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.main_services_container .grid-item .text h3 {
    color: #000;
    text-align: center;
    font-family: 'PoppinsRegular';
    font-size: 18px;
}

.main_services_container .grid-item .text p {
    color: #626262;
    text-align: center;
    font-family: 'PoppinsLight';
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.28px;
}

.main_services_container .grid-item:hover {
    background-color: #E12E2A;
}

.main_services_container .grid-item:hover .text h3 {
    color: #fff;
}

.main_services_container .grid-item:hover .text p {
    color: #FFF;
}

@media screen and (max-width: 600px) {
    .main_services_container {
        margin: 100px 0 0 0;
        width: 100%;
    }
    
    .main_services_container .content-container {
        width: 100%;
        margin-bottom: 20%;
    }

    .main_services_container .content-container h1{
        margin: 0px;
    }
    
    .main_services_container .content-container h1 .headingpart1 {
        font-size: 25px;
        line-height: 25px;
        letter-spacing: 0px;
        margin: 5px;
    }
    
    .main_services_container .content-container h1 .headingpart2 {
        font-size: 25px;
        line-height: 25px;
        letter-spacing: 0px;
        margin: 5px;
    }
    
    .main_services_container .content-container p {
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 12px;
        letter-spacing: 0.33px;
        margin: 10px 20px;
    }
    
    .main_services_container .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 60px 5px;
        width: 100%;
        height: 100%;
        margin-left: 20px;
    }
    
    .main_services_container .grid-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 80%;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid #EDEDED;
        background: #FFF;
        margin-top: 0px;
    }
    
    .main_services_container .grid-item .image_container {
        width: 90%;
        height: 100%;
        flex-shrink: 0;
        margin-top: -39%;
        /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
    }
    
    .main_services_container .grid-item .text {
        width: 100%;
        height: 100%;
        margin-top: 0;
    }
    
    .main_services_container .grid-item .image_container img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }
    
    .main_services_container .grid-item .text h3 {
        color: #000;
        text-align: center;
        font-size: 14px;
        margin: 5px;
    }
    
    .main_services_container .grid-item .text p {
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.33px;
    }
    
    .main_services_container .grid-item:hover {
        background-color: #E12E2A;
    }
    
    .main_services_container .grid-item:hover .text h3 {
        color: #fff;
    }
    
    .main_services_container .grid-item:hover .text p {
        color: #FFF;
    }
}