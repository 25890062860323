.main_my_fleet_container .main_my_fleet_box {
  margin-top: 40px;
  margin-bottom: 40px;
}

.main_my_fleet_container .main_my_fleet_box .fleet_image_detail_main {
  position: relative;
  margin-bottom: 90px;
}

.main_my_fleet_container
  .main_my_fleet_box
  .fleet_image_detail_main
  .fleet_detail_left_car {
  position: absolute;
  bottom: -80px;
  left: 0;
}

.main_my_fleet_container .main_my_fleet_box .fleet_details_box {
  background-color: #e12e2a;
  padding: 40px;
  border-radius: 30px;
}

.main_my_fleet_container .main_my_fleet_box .fleet_details_box .title {
  font-size: 45px;
  font-family: "PoppinsBold";
  color: #fff;
  line-height: 45px;
}

.main_my_fleet_container .main_my_fleet_box .fleet_details_box .description {
  font-size: 16px;
  font-family: "PoppinsRegular";
  color: #fff;
  line-height: 30px;
}

.main_my_fleet_container .main_my_fleet_box .fleet_details_box .fleet_button {
  text-align: center;
  width: 167px;
  height: 54px;
  border-radius: 40px;
  background: #000;
  margin: 10px 0px;
  color: #fff;
  border: 1px solid #000;
}

@media screen and (max-device-width: 1024px) {
  .main_my_fleet_container
    .main_my_fleet_box
    .fleet_image_detail_main
    .fleet_details_image {
    width: 100%;
    height: auto;
  }
  .main_my_fleet_container
    .main_my_fleet_box
    .fleet_image_detail_main
    .fleet_detail_left_car {
    width: 100%;
    height: auto;
  }

  .main_my_fleet_container .main_my_fleet_box .fleet_details_box .title {
    font-size: 30px;
    line-height: 30px;
  }
}
