.main_body_services_1 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.main_body_services_1 img {
  width: 100%;
  height: auto;
}

.main_body_services_1 .service_details .service_title {
  font-size: 40px;
  font-family: 'PoppinsRegular';
  line-height: 40px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.main_body_services_1 .service_details .service_subtitle {
  color: var(--Red-color, #e12e2a);
  font-size: 40px;
  font-family: 'PoppinsRegular';
  line-height: 40px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.main_body_services_1 .service_details .service_description {
  font-size: 16px;
  font-family: 'PoppinsLight';
  line-height: 30px;
  letter-spacing: 0.32px;
}

@media screen and (max-device-width: 1024px) {
  .main_body_services img {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .main_body_services_1 img {
    margin-bottom: 80px;
  }

  .main_body_services_1 .service_details {
    margin-bottom: 80px;
  }

  .main_body_services_1 .service_details .service_title {
    font-size: 30px;
    line-height: 30px;
    margin-top: 10px;
  }

  .main_body_services_1 .service_details .service_subtitle {
    font-size: 30px;
    line-height: 30px;
  }
}
