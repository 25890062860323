.terms-content-box {
  margin-top: 60px;
  margin-bottom: 60px;
}

.terms-content-box .section {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
}

.terms-content-box .section .heading1 {
  font-size: 20px;
  font-family: "PoppinsBold";
  line-height: 24px;
  color: #000;
}

.terms-content-box .section .text-terms1 {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 20px;
  color: #000;
}

.terms-content-box .section .heading {
  font-size: 20px;
  font-family: "PoppinsBold";
  line-height: 24px;
  color: #000;
  margin-top: 40px;
}

.terms-content-box .section .text-terms {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 20px;
  color: #000;
}

.terms-content-box .section .text-terms span {
  color: #c32027;
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .terms-content-box .section .heading1 {
    font-size: 14px;
    line-height: 18px;
  }

  .terms-content-box .section .heading {
    font-size: 14px;
    line-height: 18px;
  }

  .terms-content-box .section .text-terms1 {
    font-size: 14px;
    line-height: 18px;
  }

  .terms-content-box .section .text-terms {
    font-size: 14px;
    line-height: 18px;
  }
}