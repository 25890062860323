.comment-component {
  width: 80%;
  margin: auto;
  margin-bottom: 100px;
  margin-top: 120px;
}

.comment-component h1{
  color: var(--Black-Color, #000);
font-family: 'PoppinsRegular';
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 40px;
letter-spacing: 0.9px;
text-transform: uppercase;
margin-bottom: 0px;
}

.comment-component .red_heading{
  color: var(--Black-Color, #E12E2A);
font-family: 'PoppinsRegular';
font-size: 45px;
line-height: 45px;
letter-spacing: 0.9px;
text-transform: uppercase;
margin-top: 0px;
}

.comment-component .text{
  color: var(--Black-Color, #000);
/* font-family: Poppins; */
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 30px; /* 187.5% */
letter-spacing: 0.32px;
}

.comment-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  border-radius: 8px;
  padding: 0px;
  margin-bottom: 0px;
}

.comment-item {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: #FFF;
  margin-bottom: 20px;
  width: 25%;
}

.comment-owner {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
}

.comment-owner img{
  height: 42px;
  width: 42px;
  border-radius: 21px;
  margin-right: 20px;
}

.comment-content {
  margin-bottom: 10px;
  width: 380px;
  height: 179.962px;
  border-radius: 10px;
  border: 1px solid #EDEDED;
  background: #FFF;
  position: relative;
}

.comment-content p {
  width: 341px;
  height: 130px;
  color: #626262;
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 185.714% */
  letter-spacing: 0.28px;
  margin-left: 20px;
  margin-top: 30px;
}

.inverted-commas-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 38px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inverted-commas {
  position: absolute;
  bottom: 40px;
  right: 10px;
  width: 38px;
  height: 30px;
}

.inverted-commas::before,
.inverted-commas::after {
  content: '\201C'; /* Unicode character for left double quotation mark */
  font-size: 140px;
  color: #000;
  position: absolute;
  height: 50px;
}

.inverted-commas-container:hover .inverted-commas::before,
.inverted-commas-container:hover .inverted-commas::after {
  color: #ff0000; /* Red color on hover */
}

.pagination-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-dots span {
  margin: 0 5px;
  font-size: 18px;
  cursor: pointer;
  color: #D9D9D9;
}

.pagination-dots span.active {
  color: #e12e2a;
}

.star {
  color: #000;
  margin-right: 2px;
}

.star-yellow {
  color: #ffd700;
}

.star-gray {
  color: #ccc;
}

@media screen and (max-width:600px) {
  .comment-component {
    width: 90%;
    margin: auto;
    margin-bottom: 10%;
    margin-top: 5%;
  }
  
  .comment-component h1{
  font-size: 25px;
  line-height: 25px;
  letter-spacing: 0px;
  }
  
  .comment-component .red_heading{
  font-size: 25px;
  line-height: 25px;
  letter-spacing: 0px;
  }
  
  .comment-component .text{
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.33px;
  }
  
  .comment-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    border-radius: 8px;
    padding: 0px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
  }
  
  .comment-item {
    display: flex;
    flex-direction: column;
    border: none;
    background-color: #FFF;
    margin-bottom: 20px;
    width: 30%;
  }
  
  .comment-owner {
    font-weight: bold;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
  }
  
  .comment-owner img{
    height: 22px;
    width: 22px;
    border-radius: 11px;
    margin-right: 10px;
  }
  
  .comment-content {
    margin-bottom: 10px;
    width: 100%;
    height: 90px;
    border-radius: 10px;
    border: 1px solid #EDEDED;
    background: #FFF;
    position: relative;
  }
  
  .comment-content p {
    width: 90%;
    height: 70%;
    color: #626262;
    /* font-family: Poppins; */
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: 8px;
    /* 185.714% */
    letter-spacing: 0px;
    margin-left: 10px;
    margin-top: 10px;
  }
  
  .inverted-commas-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 38px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inverted-commas {
    position: absolute;
    bottom: 5px;
    right: -5px;
    width: 38px;
    height: 30px;
  }
  
  .inverted-commas::before,
  .inverted-commas::after {
    content: '\201C'; /* Unicode character for left double quotation mark */
    font-size: 80px;
    color: #000;
    position: absolute;
    height: 50px;
  }
  
  .inverted-commas-container:hover .inverted-commas::before,
  .inverted-commas-container:hover .inverted-commas::after {
    color: #ff0000; /* Red color on hover */
  }
  
  .pagination-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-dots span {
    margin: 0 5px;
    font-size: 18px;
    cursor: pointer;
    color: #D9D9D9;
  }
  
  .pagination-dots span.active {
    color: #e12e2a;
  }

  .comment-component .comment-list .comment-item .comment-owner .name_rating span{
    font-size: 12px;
  }
}