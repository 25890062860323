body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.main_form_container {
  background-size: cover;
  background-position: center;
  height: 700px;
}

.inner_form_container {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
}

.main_form_container .left-div {
  padding: 20px;
  text-decoration: none;
}

.main_form_container .left-div .main_text {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  line-height: 55px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  text-decoration: none;
}

.main_form_container .left-div .main_text span {
  color: #e12e2a;
}

.main_form_container .left-div .text {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.33px;
  margin-bottom: 13%;
  height: 120px;
}

.main_form_container .left-div .main_text .h1 {
  margin-top: 130px;
  margin-bottom: 5px;
  text-decoration: none;
}

.main_form_container .left-div .main_text .header_heading {
  font-size: 40px;
  font-family: "PoppinsBold";
  line-height: 40px;
  color: #fff;
  min-height: 580px;
  display: flex;
  align-items: center;
}

.main_form_container .left-div .main_text .h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 130%;
}

.main_form_container .Click_here {
  background-color: #e12e2a;
  color: #fff;
  width: 166.967px;
  height: 54px;
  border-radius: 200px;
}

.main_form_container .text {
  font-size: 18px;
  color: #333;
}

.main_form_container .right-div {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 60px;
}

.main_form_container .form_btns {
  margin-left: 30px;
}

.main_form_container .form_btns button {
  width: 31.5%;
  height: 45px;
}

.main_form_container .form_btn1 {
  border-radius: 10px 0px 0px 0px;
  transition: all 0.5s;
}

.main_form_container .form_btn1:hover {
  background-color: #e12e2a;
}

.main_form_container .form_btn2 {
  transition: all 0.5s;
}

.main_form_container .form_btn2:hover {
  background-color: #e12e2a;
}

.main_form_container .form_btn3 {
  transition: all 0.5s;
}

.main_form_container .form_btn3:hover {
  background-color: #e12e2a;
}

.main_form_container .form_btn3 {
  border-radius: 0px 10px 0px 0px;
}

.main_form_container .form_inputs {
  display: flex;
  background-color: #000;
  width: 100%;
  border-radius: 10px;
  flex-direction: column;
  padding: 30px;
  margin: 0px;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgba(105, 105, 105, 0.88);
}

.main_form_container .form_inputs .input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: x-small;
  width: 100%;
  height: 50.25px;
  padding: 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  margin: 8px 0px;
}

.main_form_container .form_inputs .input input:focus-visible {
  outline: 0;
}

.main_form_container .form_inputs .input input {
  padding: 5px 10px;
  width: 100%;
  border: none;
  height: 30px;
  padding-bottom: 0px;
  font-size: 16px;
  font-family: "PoppinsRegular";
}

.main_form_container .form_inputs .input select {
  width: 96%;
  border: none;
  height: 20px;
  padding-bottom: 0px;
  margin: 0px;
  padding: 1px 10px 1px 10px;
  border: none;
  font-size: 16px;
  font-family: "PoppinsRegular";
  color: #747474;
  text-align: left;
}

.main_form_container .form_inputs .input .custom-ddl {
  width: 96%;
  border: none;
  height: auto;
  padding-bottom: 0px;
  margin: 0px;
  padding: 1px 10px 1px 10px;
  border: none;
  font-size: 16px;
  font-family: "PoppinsRegular";
  color: #747474;
  text-align: left;
}

.main_form_container .form_inputs .input .custom-ddl #option-ddl {
  width: 100%;
  background-color: #fff !important;
  font-size: 14px;
  font-family: "PoppinsRegular";
}

.main_form_container .form_inputs .input span {
  padding-left: 10px;
  padding-top: 10px;
  width: 100%;
  font-size: small;
}

.main_form_container .form_inputs .input .input_field {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}

.main_form_container .form_inputs .input .input_field_error {
  border: 3px solid #e12e2a;
  border-radius: 7px;
}

.main_form_container .form_inputs .input .input_field1 {
  width: 80%;
}

.main_form_container .form_inputs .input .input_field2 {
  width: 100%;
}

.main_form_container .form_inputs .input .input_field1 .text_place {
  display: block;
  font-size: 13px;
  font-family: "PoppinsRegular";
  color: #747474;
  padding-top: 5px;
}

.main_form_container .form_inputs .input .different1 .main_count_box {
  display: flex;
  justify-content: space-around;
  align-items: end;
  margin-right: 20px;
}

.main_form_container .form_inputs .input .different1 .main_count_box span {
  font-size: 14px;
  font-family: "PoppinsBold";
  color: #000;
}

.main_form_container .form_inputs .input .input_field2 .text_place {
  display: block;
  font-size: 16px;
  font-family: "PoppinsRegular";
  color: #747474;
  padding-top: 5px;
}

.main_form_container .form_inputs .input .different .main_count_box {
  display: flex;
  justify-content: space-around;
  align-items: end;
  margin-right: 20px;
}

.main_form_container .form_inputs .input .different .main_count_box span {
  font-size: 14px;
  font-family: "PoppinsBold";
  color: #000;
}

.main_form_container .form_inputs .input .different .main_count_box img {
  cursor: pointer;
}

.main_form_container .form_inputs .input img {
  cursor: pointer;
  padding-left: 15px;
  padding-right: 3px;
}

.main_form_container input#session-date {
  position: relative;
  overflow: hidden;
}

.main_form_container input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(52);
}

.main_form_container input#session-time {
  position: relative;
  overflow: hidden;
}

.main_form_container input[type="time"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(52);
}

.main_form_container .form_inputs button {
  width: 100%;
  border-radius: 5px;
  background: #e12e2a;
  margin: 20px 0px;
}

.main_form_container .option {
  margin-bottom: 10px;
}

.main_form_container label {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
  width: 49%;
  border-radius: 7px;
  background: #fff;
}

.main_form_container .different {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
  width: 120%;
  border-radius: 7px;
  background: #fff;
}

.main_form_container .different .ddl-parent {
  position: relative;
}

.main_form_container .different .ddl-lv {
  font-size: 16px !important;
  font-family: "PoppinsRegular";
  color: #747474;
}

.main_form_container .different .ddl-parent .ddl-options {
  position: absolute;
  z-index: 9999;
  top: 35px;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding-left: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.main_form_container .different .ddl-parent .ddl-options p {
  font-size: 14px;
  font-family: "PoppinsBold";
  color: #000;
  transition: all 0.5s;
}

.main_form_container .different .ddl-parent .ddl-options p:hover {
  color: #e12e2a;
}

.main_form_container .different .right-ico-ddl {
  padding-left: 0 !important;
  padding-right: 10px !important;
}

.main_form_container .icon {
  margin-right: 10px;
}

.main_form_container button {
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.main_form_container button.active {
  background-color: red;
}

.disable {
  display: none;
}

@media screen and (max-width: 768px) {
  .main_form_container {
    height: 100%;
  }

  .inner_form_container {
    padding-bottom: 40px;
  }

  .main_form_container .left-div .main_text {
    margin-top: 80px;
    color: #fff;
    font-size: 14px;
    font-family: "PoppinsRegular";
    font-style: normal;
    line-height: 30px;
    letter-spacing: 1.1px;
    text-transform: uppercase;
  }

  .main_form_container .left-div .text {
    color: #fff;
    font-size: 15px;
    font-family: "PoppinsLight";
  }

  .main_form_container .left-div .main_text .h1 {
    margin-top: 10px;
    font-size: 30px !important;
    font-family: "PoppinsBold";
    margin-bottom: 5px;
  }

  .main_form_container .left-div .main_text .header_heading {
    font-size: 35px;
    min-height: 380px;
    text-align: center;
  }

  .main_form_container .left-div .main_text .h2 {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .main_form_container .Click_here {
    position: relative;
    background-color: #e12e2a;
    color: #fff;
    width: 150px;
    height: auto;
    margin-top: 130px;
    margin-bottom: 20px;
    border-radius: 200px;
  }

  .main_form_container .right-div {
    margin-top: 40px !important;
  }

  .main_form_container .left-div,
  .main_form_container .right-div {
    width: 100%;
    margin: 0;
    padding: 5px 5px;
    margin-left: 0;
  }

  .main_form_container .form_btns {
    margin-left: 10px;
    display: flex;
    width: 100%;
  }

  .main_form_container .form_btns button {
    width: 31.6%;
    height: auto;
    font-size: 11px;
  }

  .main_form_container .form_inputs {
    width: 100%;
    padding: 10px 0px;
  }

  .main_form_container .form_inputs .input {
    width: 90%;
    height: 35px;
  }

  .main_form_container .form_inputs .input input {
    padding: 0px 10px;
    width: 98%;
    height: 32px;
    font-size: 13px;
  }

  .main_form_container .form_inputs .input select {
    height: 15px;
    padding: 0px 6px 0px 6px;
    font-size: 13px;
  }

  .main_form_container .form_inputs .input span {
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
    font-size: 13px !important;
  }

  .main_form_container .form_inputs .input .input_field1 {
    width: 80%;
  }

  .main_form_container .form_inputs .input .input_field2 {
    width: 100%;
  }

  .main_form_container .form_inputs .input .mobileBoxView {
    display: block;
  }

  .main_form_container .form_inputs .input .input_field .mobileView {
    width: 100%;
    height: 34px;
  }

  .main_form_container .form_inputs .input .different1 {
    margin: 15px 0px;
  }

  .main_form_container .form_inputs .input .input_field1 .text_place {
    padding-top: 0;
  }

  .main_form_container .form_inputs .input .different1 .main_count_box span {
    padding-top: 2px;
  }

  .main_form_container .form_inputs .input .different1 .main_count_box {
    align-items: center;
  }

  .main_form_container .form_inputs .input .different1 .main_count_box img {
    width: 30px;
    height: auto;
    padding-right: 0;
    position: relative;
    z-index: 9999;
  }

  .main_form_container .form_inputs .input .different1 .main_count_box span {
    font-size: 11px;
  }

  .main_form_container .form_inputs .input .input_field2 .text_place {
    padding-top: 0;
    font-size: 13px;
  }

    .main_form_container .different .ddl-parent .ddl-options {
      top: 27px;
    }

        .main_form_container .different .ddl-parent .ddl-options p {
          font-size: 12px;
        }

  .main_form_container .form_inputs .input .different .main_count_box span {
    padding-top: 2px;
  }

  .main_form_container .form_inputs .input .different .main_count_box {
    align-items: center;
  }

  .main_form_container .form_inputs .input .different .main_count_box img {
    width: 30px;
    height: auto;
    padding-right: 0;
  }

  .main_form_container .form_inputs .input .different .main_count_box span {
    font-size: 11px;
  }

  .main_form_container .form_inputs .input img {
    padding-left: 10px;
    padding-right: 3px;
  }

  .main_form_container input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: no-repeat;
    border-width: thin;
  }

  .main_form_container input[type="time"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: no-repeat;
    border-width: thin;
  }

  .main_form_container .form_inputs button {
    width: 90%;
    height: auto;
    background: #e12e2a;
    margin: 10px 0px;
  }
}
