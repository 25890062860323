.clients_main_container {
  background-color: #E4DEDE36;
  margin-top: 100px;
  margin-bottom: 50px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.clients_main_container .clients_inner_container .text1 {
  font-size: 36px;
  font-family: "PoppinsLight";
  line-height: 40px;
  color: #333;
}

.clients_main_container .clients_inner_container .text1 span {
  font-size: 36px;
  font-family: "PoppinsBold";
  line-height: 40px;
  color: #333;
}

.clients_main_container .clients_inner_container .text2 {
  width: 70%;
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 20px;
  color: #888888;
  margin-top: 60px;
  text-align: justify;
}

.clients_main_container .clients_inner_container .contact_btn {
  display: inline-block;
  margin-top: 80px;
  margin-bottom: 40px;
}

.clients_main_container .clients_inner_container .contact_btn .btn_box {
  cursor: pointer;
  background-color: #e12e2a;
  padding: 10px 50px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-family: "PoppinsRegular";
  line-height: 20px;
  transition: all 0.5s;
}

.clients_main_container .clients_inner_container .contact_btn .btn_box:hover {
  background-color: #000;
}

.clients_main_container .clients_inner_container .client_img {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

@media screen and (max-width: 1024px) {
  .clients_main_container .clients_inner_container .text1 {
    font-size: 24px;
    line-height: 30px;
  }

  .clients_main_container .clients_inner_container .text2 {
    width: 100%;
  }

  .clients_main_container .clients_inner_container .text1 span {
    font-size: 24px;
    line-height: 30px;
  }

  .clients_main_container .clients_inner_container .contact_btn .btn_box {
    padding: 10px 30px;
  }
}
