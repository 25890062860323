.App {
  background-color: #fff;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255); /* White background with some transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
}
