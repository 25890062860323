.main_ECSprovider_container {
    display: flex;
}

.main_ECSprovider_container .left_container {
    width: 56.5%;
    background-image: url('../assets/leftcar.webp');
    background-size: cover;
    background-position: center;
    margin: 0%;
}

.main_ECSprovider_container .left_container .left_container_body {
    background: rgba(0, 0, 0, 0.81);
    margin: 0%;
    margin-top: -5px;
    height: 100%;
}

.main_ECSprovider_container .left_container h1 {
    color: #FFF;
    font-family: 'PoppinsRegular';
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    margin: 5px 100px
}

.main_ECSprovider_container .left_container .upper_heading {
    padding-top: 70px;
}

.main_ECSprovider_container .left_container .red {
    color: var(--Red-color, #E12E2A);
    font-family: 'PoppinsLight';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 30px 100px;
}

.main_ECSprovider_container .left_container .read_button {
    margin: 0px 100px 100px 100px;
    background-color: #E12E2A;
    color: #FFF;
    width: 166.967px;
    height: 54px;
    border-radius: 200px;
    border: none;
}

.main_ECSprovider_container .left_container .text {
    color: #FFF;
    font-size: 16px;
    font-family: 'PoppinsLight';
    line-height: 30px;
    text-align: justify;
    letter-spacing: 0.32px;
    margin: 40px 100px;
}

.main_ECSprovider_container .right_container {
    width: 43.5%;
    background: url('../assets/rightcar.webp');
    position: relative;
    z-index: 1;
    background-size: cover;
    background-position: center;
}

.main_ECSprovider_container .right_container .right_container_body {
    margin: 0%;
    height: 100%;
    position: relative;
}

.main_ECSprovider_container .right_container .right_container_body::after {
    content: '';
    position: absolute;
    background: rgba(190, 0, 0, 0.87);
    mix-blend-mode: multiply;
    z-index: -1;
    inset: 0;
}

/* .image_car_container {
    z-index: 1;
    position: absolute;
    top: 0px;
    overflow: hidden;
    left: 0px;
} */

.main_ECSprovider_container .right_container {
    position: relative;
}
.main_ECSprovider_container .right_container img {
    position: absolute;
    z-index: 9999;
    right: 0;
    bottom: -170px;
    width: 807px;
    height: 585px;
}

@media screen and (max-width: 768px) {
    .main_ECSprovider_container {
        margin-top: 50px;
        display: flex;
        width: 100%;
        height: 100%;
    }
    
    .main_ECSprovider_container .left_container {
        width: 60%;
        background-image: url('../assets/leftcar.webp');
        background-size: cover;
        background-position: center;
        margin: 0%;
    }
    
    .main_ECSprovider_container .left_container .left_container_body {
        background: rgba(0, 0, 0, 0.81);
        margin: 0%;
        margin-top: -5px;
        height: 100%;
    }
    
    .main_ECSprovider_container .left_container h1 {
        color: #FFF;
        font-family: 'PoppinsBold';
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0px;
        text-transform: uppercase;
        margin: 5px 10px
    }
    
    .main_ECSprovider_container .left_container .upper_heading {
        padding-top: 30px;
        text-align: center;
    }
    
    .main_ECSprovider_container .left_container .red {
        color: var(--Red-color, #E12E2A);
        font-family: 'PoppinsRegular';
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        margin: 10px 10px;
    }
    
    .main_ECSprovider_container .left_container .read_button {
        margin: 20px 0 50px 10px;
        background-color: #E12E2A;
        color: #FFF;
        width: 60%;
        height: auto;
        border-radius: 200px;
        border: none;
        font-size: 16px;
        padding: 10px 0;
    }
    
    .main_ECSprovider_container .left_container .text {
        color: #FFF;
        font-family: 'PoppinsLight';
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        /* 187.5% */
        letter-spacing: 0.33px;
        margin: 20px 10px;
        text-align: justify;
    }
    
    .main_ECSprovider_container .right_container {
        width: 40%;
        background: url('../assets/rightcar.webp');
        position: relative;
        z-index: 1;
        background-size: cover;
        background-position: center;
    }
    
    .main_ECSprovider_container .right_container .right_container_body {
        margin: 0%;
        height: 100%;
        position: relative;
    }
    
    .main_ECSprovider_container .right_container .right_container_body::after {
        content: '';
        position: absolute;
        background: rgba(190, 0, 0, 0.87);
        mix-blend-mode: multiply;
        z-index: -1;
        inset: 0;
    }
}

@media screen and (max-width:450px) {
    .main_ECSprovider_container .right_container img {
        display: none;
        position: absolute;
        width: 190px;
        height: auto;
        top: 77%;
        left:120%
    }
}

@media screen and (max-width:420px) {
    .main_ECSprovider_container .right_container img {
        display: none;
        position: absolute;
        width: 190px;
            height: auto;
        top: 80%;
        left:120%
    }
}

@media screen and (max-width:380px) {
    .main_ECSprovider_container .right_container img {
        display: none;
        position: absolute;
        width: 190px;
        height: auto;
        top: 82%;
        left:150%
    }
}