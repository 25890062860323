.main_footer_container {
  background-color: rgba(0, 0, 0, 1);
}

.main_footer_container .inner-div1 {
  padding: 10px;
  padding-top: 80px;
}

.main_footer_container .inner-div1 img {
  width: 130px;
  height: auto;
}

.main_footer_container .inner-div1 p {
  color: #fff;
  font-family: "PoppinsLight";
  font-size: 14px;
  font-style: normal;
  text-align: justify;
  line-height: 26px;
  margin-top: 40px;
}

.main_footer_container .inner-div2 p {
  color: #fff;
  font-size: 14px;
  font-family: "PoppinsLight";
  font-style: normal;
  font-weight: 200;
  line-height: 22px;
}

.main_footer_container .inner-div2 .title {
  color: #fff;
  padding: 20px 0px;
  padding-top: 60px;
  font-size: 18px;
  font-style: normal;
  font-weight: 250;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.main_footer_container .inner-div2 .company_links {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  font-size: 14px;
  font-family: "PoppinsLight";
  line-height: 300;
}

.main_footer_container .inner-div3 p {
  color: #fff;
  font-size: 14px;
  font-family: "PoppinsLight";
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.main_footer_container .inner-div3 .title {
  color: #fff;
  padding: 20px 0px;
  padding-top: 60px;
  font-size: 18px;
  font-family: "PoppinsLight";
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.28px;
}

.main_footer_container .inner-div3 .address1 {
  padding-bottom: 0px;
  line-height: 50px;
  margin-top: 0px;
  display: flex;
  justify-self: flex-start;
  align-items: flex-start;
}

.main_footer_container .inner-div3 .address1 .key {
  width: 26%;
  color: #e12e2a;
}

.main_footer_container .inner-div3 .address2 {
  padding-bottom: 0px;
  line-height: 50px;
  margin-top: 0px;
  display: flex;
  justify-self: flex-start;
  align-items: flex-start;
}

.main_footer_container .inner-div3 .address2 .key {
  width: 60%;
  color: #e12e2a;
}

.main_footer_container .inner-div3 .address3 {
  padding-bottom: 0px;
  line-height: 50px;
  margin-top: 0px;
  display: flex;
  justify-self: flex-start;
  align-items: flex-start;
}

.main_footer_container .inner-div3 .address3 .key {
  width: 45%;
  color: #e12e2a;
}

.main_footer_container .inner-div3 span {
  color: #e12e2a;
}

.main_footer_container .inner-div3 .icons {
  display: flex;
  justify-content: flex-start;
  margin-left: -10px;
  margin-top: 0px;
}

.main_footer_container .inner-div3 .icons .icon {
  background-color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.main_footer_container .inner-div3 .icons .icon:hover {
  background-color: #e12e2a;
}

.main_footer_container .inner-div3 .icons .icon img {
  height: 36px;
  width: 36px;
}

.main_footer_container .footer_bottom {
  display: flex;
  margin-top: 60px;
  padding: 0px;
  width: 100%;
  height: 10%;
  justify-content: space-around;
  align-items: center;
  border-top: 2px solid #e12e2a;
}

.main_footer_container .footer_bottom p {
  padding-top: 20px;
  color: #fff;
}

.main_footer_container .footer_bottom p .red {
  color: #e12e2a;
}

.main_footer_container .footer_bottom .aboutus {
  display: flex;
  justify-content: space-between;
  width: 558px;
}

.main_footer_container .footer_bottom p {
  color: #fff;
  font-size: 14px;
  font-family: "PoppinsLight";
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.28px;
}

@media screen and (max-width: 600px) {
  .main_footer_container .footer_main_body {
    width: 100%;
    height: auto;
  }

  .main_footer_container .inner-div1 p {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
  }

  .main_footer_container .inner-div2 {
    width: 100%;
    height: 100%;
  }

  .main_footer_container .inner-div2 p {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.33px;
  }

  .main_footer_container .inner-div2 .title {
    color: #fff;
    padding: 5% 0%;
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.33px;
  }

  .main_footer_container .inner-div3 {
    width: 100%;
    height: 100%;
  }

  .main_footer_container .inner-div3 p {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }

  .main_footer_container .inner-div3 .title {
    color: #fff;
    padding: 5% 0%;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }

  .main_footer_container .inner-div3 .address1 {
    align-items: center;
  }

  .main_footer_container .inner-div3 .address1 .key {
    width: 24%;
  }

  .main_footer_container .inner-div3 .address2 .key {
    width: 80%;
  }

  .main_footer_container .inner-div3 .address3 .key {
    width: 60%;
  }

  .main_footer_container .inner-div3 span {
    color: #e12e2a;
  }

  .main_footer_container .inner-div3 .icons {
    display: flex;
    justify-content: flex-start;
    margin-left: 12px;
    margin-top: 0px;
  }

  .main_footer_container .inner-div3 .icons .icon {
    background-color: #fff;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main_footer_container .inner-div3 .icons .icon:hover {
    background-color: #e12e2a;
  }

  .main_footer_container .inner-div3 .icons .icon img {
    height: 30px;
    width: 30px;
  }

  .main_footer_container .footer_bottom {
    display: flex;
    margin-top: 10px;
    padding: 0px;
    width: 100%;
    height: 10%;
    justify-content: space-around;
    align-items: flex-start;
    border-top: 2px solid #e12e2a;
  }

  .main_footer_container .footer_bottom p {
    color: #fff;
  }

  .main_footer_container .footer_bottom_text {
    width: 100%;
  }

  .main_footer_container .footer_bottom p .red {
    color: #e12e2a;
  }

  .main_footer_container .footer_bottom p {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.33px;
  }
}

@media screen and (max-width: 400px) {
  .main_footer_container .footer_bottom {
    margin-top: 20px;
  }
}
