.Ourfleet_main_container {
  margin-top: 50px;
  margin-bottom: 100px;
}

.Ourfleet_main_container .upper_div {
  display: flex;
  justify-content: space-between;
}

.Ourfleet_main_container .upper_div h1 {
    font-size: 45px;
      font-family: 'PoppinsRegular';
  letter-spacing: 0.9px;
  text-transform: uppercase;
  margin: 0px;
}

.Ourfleet_main_container .upper_div h1 span {
  color: #e12e2a;
}

.Ourfleet_main_container .upper_div p {
  color: #000;
  font-family: 'PoppinsLight';
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.32px;
}

.Ourfleet_main_container .upper_div .right_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 46%;
  margin-top: 30px;
}

.Ourfleet_main_container .upper_div .right_div button {
  width: 166.967px;
  height: 54px;
  border-radius: 200px;
  background: #fff;
  color: #000;
  border: #000 solid;
  border-width: 2px;
  font-size: 16px;
  font-family: 'PoppinsLight';
}

.Ourfleet_main_container .upper_div .right_div .activebtn {
  background-color: #e12e2a;
  color: #fff;
  border: none;
}

.Ourfleet_main_container .lower_div {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 60px;
}

.Ourfleet_main_container .lower_div .lower_div_item {
  width: 380px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: url("../assets/carbackground.webp"),
    rgb(245, 245, 245) 50% / cover no-repeat;
  border-radius: 300px;
  /* background: #EFEFEF; */
}

.Ourfleet_main_container .lower_div .internal-details {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Ourfleet_main_container .lower_div .internal-details .inner-box {
  padding-right: 20px;
}

.Ourfleet_main_container .lower_div .internal-details .inner-box .key {
  width: 20px;
  height: auto;
}

.Ourfleet_main_container .lower_div .internal-details .inner-box .value {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #000;
  padding-left: 7px;
}

.Ourfleet_main_container .lower_div .lower_div_item h1 {
  color: #000;
  text-align: center;
  font-size: 28px;
  font-family: 'PoppinsRegular';
  line-height: 26px;
  letter-spacing: 0.44px;
}

.Ourfleet_main_container .lower_div .lower_div_item img {
  margin-bottom: -100px;
  width: 366px;
  height: 152px;
  transition: transform 0.5s ease;
  cursor: pointer;
}

.Ourfleet_main_container .lower_div .lower_div_item img:hover {
  transform: scale(1.2);
}

/* Fleet Page CSS */
.Ourfleet_main_container .lower_div_our_fleet {
  margin-top: 50px;
}

.Ourfleet_main_container .lower_div_our_fleet .fleet_car_box {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

.Ourfleet_main_container .lower_div_our_fleet .fleet_car_box .fleet_car_bg {
  width: 100%;
  height: auto;
}

.Ourfleet_main_container .lower_div_our_fleet .fleet_car_box .fleet_car_img {
  position: absolute;
  top: 160px;
  left: 65px;
  width: 290px;
  height: auto;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.Ourfleet_main_container .lower_div_our_fleet .fleet_car_box .fleet_car_img:hover {
  transform: scale(1.2);
}

.Ourfleet_main_container .lower_div_our_fleet .fleet_car_box .heading {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 40px;
  font-size: 22px;
  font-family: "PoppinsRegular";
  line-height: 26px;
  color: #000;
  transition: all 0.5s;
}

.Ourfleet_main_container .lower_div_our_fleet .fleet_car_box .heading:hover {
  color: #e12e2a;
}

.Ourfleet_main_container .lower_div_our_fleet .fleet_car_box .description {
  font-size: 14px;
  font-family: "PoppinsLight";
  line-height: 26px;
  color: #626262;
}

.Ourfleet_main_container .lower_div_our_fleet .internal-details {
  margin-top: 80px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Ourfleet_main_container
  .lower_div_our_fleet
  .internal-details
  .inner-box
  .key {
  width: 20px;
  height: auto;
}

.Ourfleet_main_container .lower_div_our_fleet .inner-box .value {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #000;
  padding-left: 7px;
}

@media screen and (max-width: 600px) {
  .Ourfleet_main_container {
    margin: 10px 0px;
    margin-top: 50px;
    width: 100%;
    height: 100%;
  }

  .Ourfleet_main_container .lower_div {
    margin-left: 0;
  }

  .Ourfleet_main_container .lower_div .lower_div_item {
    width: 320px;
    height: 320px;
    margin-bottom: 60px;
  }

  .Ourfleet_main_container .lower_div .lower_div_item h1 {
    font-size: 20px;
  }

  .Ourfleet_main_container .lower_div .lower_div_item img {
    margin-bottom: -50px;
    width: 286px;
    height: 120px;
  }

  .Ourfleet_main_container .lower_div .internal-details {
    margin-top: 0;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: flex-start;
  }
  .Ourfleet_main_container .lower_div .internal-details .inner-box {
    margin-bottom: 10px;
  }

  .Ourfleet_main_container .upper_div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .Ourfleet_main_container .upper_div .left_div {
    width: 46%;
    margin-left: 2%;
  }

  .Ourfleet_main_container .upper_div h1 {
    font-size: 25px;
    line-height: 25px;
    letter-spacing: 0px;
  }

  .Ourfleet_main_container .upper_div p {
    color: #000;
    /* font-family: Poppins; */
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    /* 187.5% */
    letter-spacing: 0.33px;
    width: 90%;
  }

  .Ourfleet_main_container .upper_div .right_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-top: 30px;
    margin-right: 2%;
  }

  .Ourfleet_main_container .upper_div .right_div button {
    width: 30%;
    height: 60%;
    border-radius: 200px;
    background: #fff;
    color: #000;
    border: #000 solid;
    border-width: 1px;
    font-size: 10px;
  }

  .Ourfleet_main_container .upper_div .right_div .activebtn {
    background-color: #e12e2a;
    color: #fff;
    border: none;
  }

  /* Fleet Page */
  .Ourfleet_main_container .lower_div_our_fleet .fleet_car_box {
    margin-bottom: 50px;
  }
  .Ourfleet_main_container .lower_div_our_fleet .fleet_car_box .fleet_car_img {
    top: 120px;
    left: 20px;
    width: 290px;
  }
}
