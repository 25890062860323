.privacy-content-box {
  margin-top: 60px;
  margin-bottom: 60px;
}

.privacy-content-box .section1 p {
  font-size: 16px;
  font-family: 'PoppinsLight';
  line-height: 20px;
  color: #000;
}

.privacy-content-box .section {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
  margin-top: 40px;
}

.privacy-content-box .heading-privacy {
  font-size: 20px;
  font-family: 'PoppinsBold';
  line-height: 24px;
  color: #000;
}

.privacy-content-box .text-privacy {
  font-size: 16px;
  font-family: 'PoppinsLight';
  line-height: 20px;
  color: #000;
}

.privacy-content-box .text-privacy span {
  color: #c32027;
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .privacy-content-box .section1 p {
    font-size: 14px;
    line-height: 18px;
  }

  .privacy-content-box .heading-privacy {
    font-size: 16px;
    line-height: 20px;
  }

  .privacy-content-box .text-privacy {
    font-size: 14px;
    line-height: 18px;
  }
}