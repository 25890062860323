.main_gallery_body {
  margin-top: 40px;
  margin-bottom: 40px;
}

.main_gallery_body .heading {
  text-align: center;
  font-size: 40px;
  font-family: 'PoppinsRegular';
  line-height: 40px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.main_gallery_body .heading span {
  font-size: 40px;
  font-family: 'PoppinsRegular';
  line-height: 40px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: var(--Red-color, #e12e2a);
}

.main_gallery_body .description {
  text-align: center;
  font-size: 16px;
  font-family: 'PoppinsLight';
  line-height: 30px;
  letter-spacing: 0.32px;
}

/* Images Gallery */
.gallery_box_se {
  width: 100%;
  height: 100%;
}

.gallery_box_se .trans {
  transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

.gallery_box_se .top {
  margin: 30px 0;
}

.gallery_box_se .top .card-box {
  position: relative;
}

.gallery_box_se .top .card-box a .image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 60px;
}

.gallery_box_se .top .card-box a .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(194, 34, 40, 0.39);
  border-radius: 20px;
}

.gallery_box_se .top .card-box:hover a .overlay {
  opacity: 1;
  background-color: rgba(194, 34, 40, 0.39);
}

.gallery_box_se .top .card-box a .icon {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Images Slider */

.lightbox {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
  opacity: 0;
  pointer-events: none;
}

.lightbox img {
  max-width: 90%;
  max-height: 80%;
  position: relative;
  top: -100%;
  /* Transition */
  transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

.lightbox:target {
  outline: none;
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 1.2s ease;
  -moz-transition: all 1.2s ease;
  -ms-transition: all 1.2s ease;
  -o-transition: all 1.2s ease;
  -webkit-transition: all 1.2s ease;
}

.lightbox:target img {
  top: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.main_gallery_body .load_btn {
  width: 100%;
  text-align: center;
}

.main_gallery_body .load_more {
  text-align: center;
  width: 167px;
  height: 54px;
  border-radius: 40px;
  background: #e12e2a;
  margin: 10px 0px;
  color: #fff;
  border: 1px solid #e12e2a;
}
