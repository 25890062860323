.payment-box {
  width: 100%;
  margin-top: 5%;
}

.payment-box .header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.payment-box .header img {
  width: 40px;
  height: 40px;
}

.payment-box .header p {
  margin-bottom: 0;
  padding-left: 8px;
  font-size: 16px;
  font-family: "PoppinsRegular";
  line-height: 20px;
}

.payment-box .content {
  margin-top: 30px;
}

.payment-box .content .type-category {
  font-size: 20px;
  font-family: "PoppinsRegular";
  line-height: 26px;
  color: #838383;
}

.payment-box .amount {
  color: #333;
  font-size: 36px;
  font-family: "PoppinsBold";
  line-height: 42px;
}

.payment-box .location {
  width: 70%;
  margin-top: 15px;
  color: #838383;
  font-size: 16px;
  font-family: "PoppinsRegular";
  line-height: 24px;
}

.payment-box .payment-form {
  box-shadow: -8px 0px 50px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 50px;
}

.payment-box .payment-form p {
  color: #333;
  font-size: 26px;
  font-family: "PoppinsRegular";
  line-height: 32px;
}

.payment-box .payment-form .email_input {
  background-color: #f7f7f7;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding: 13px;
}

.payment-box .payment-form .email_input label {
  font-size: 16px;
  font-family: "PoppinsRegular";
  line-height: 18px;
  color: #666;
}

.payment-box .payment-form .email_input input {
  width: 90%;
  background-color: transparent;
  border: none;
  padding-left: 40px;
  font-size: 16px;
  font-family: "PoppinsRegular";
  line-height: 18px;
  color: #666;
}

.payment-box .payment-form .heading {
  margin-top: 20px;
  font-size: 16px;
  font-family: "PoppinsRegular";
  line-height: 24px;
  color: #333;
}

.payment-box .payment-form .normal-input {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 15px;
  padding: 13px;
  font-size: 16px;
  font-family: "PoppinsRegular";
  line-height: 18px;
  color: #666;
}

.payment-box .payment-form .normal-input::placeholder {
  font-size: 16px;
  font-family: "PoppinsRegular";
  line-height: 18px;
  color: #666;
}

.payment-box .payment-form .pay-button {
  margin-top: 50px;
  padding: 12px;
  font-size: 18px;
  font-family: "PoppinsRegular";
  line-height: 24px;
  color: #fff;
  border-radius: 10px;
}

@media screen and (max-width: 1024px) {
  .payment-box .content .type-category {
    font-size: 14px;
    line-height: 20px;
  }

  .payment-box .amount {
    font-size: 24px;
    line-height: 30px;
  }

  .payment-box .location {
    font-size: 14px;
    line-height: 18px;
  }

  .payment-box .payment-form {
    padding: 20px;
  }

  .payment-box .payment-form p {
    font-size: 16px;
    line-height: 20px;
  }

  .payment-box .payment-form .email_input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .payment-box .payment-form .email_input label {
    font-size: 14px;
  }

  .payment-box .payment-form .email_input input {
    font-size: 14px;
  }

  .payment-box .payment-form .heading {
    font-size: 14px;
  }

  .payment-box .payment-form .normal-input {
    font-size: 14px;
  }

  .payment-box .payment-form .normal-input::placeholder {
    font-size: 14px;
  }

  .payment-box .payment-form .pay-button {
    font-size: 14px;
    padding: 10px;
  }
}
