.main_booking_form_container {
  background-color: #e12e2a;
  padding: 100px 0 20px 0;
}

.booking_form_inputs .input .input_field3 {
  width: 200px;
  position: relative;
}

.booking_form_inputs .input .input_field2 {
  width: 100%;
  position: relative;
}

.booking_form_inputs .input input {
  padding: 5px 10px;
  width: 140px;
  border: none;
  height: 20px;
  padding-bottom: 0px;
  background-color: transparent;
  color: #fff;
}

.booking_form_inputs .input input::placeholder {
  color: #fff;
}

.main_booking_form_container .underline {
  position: absolute;
  bottom: 0;
  left: 87px;
  width: 160px;
  height: 2px;
  background-color: #fff;
}

.booking_form_inputs .input input:focus-visible {
  outline: 0;
}

.booking_form_inputs button {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.25);
  margin: 0px 20px;
  color: #fff;
  /* border: solid rgba(255, 255, 255, 1); */
  border: none;
  border-width: 1px;
  margin-right: 0px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.main_booking_form_container .booking_form_inputs .booking_update_btn {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.booking_form_inputs input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: no-repeat;
  border-width: thin;
}

.booking_form_inputs input[type="time"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: no-repeat;
  border-width: thin;
}

.booking_form_inputs .input img {
  padding-left: 15px;
  padding-right: 3px;
}

.booking_form_inputs .input select {
  width: 160px;
  height: 25px;
  border: none;
  padding-bottom: 0px;
  margin: 0px;
  padding: 1px 10px 1px 10px;
  border: none;
  color: #fff;
  /* margin-top: 7px; */
  /* background-color: #FFF; */
}

.booking_form_inputs .input select option {
  color: #000;
}

.main_booking_form_container .logo-container {
  background-color: transparent;
  width: 180px;
  height: 40px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  /* margin-top: -70px; */
}

.main_booking_form_container .hhh {
  background-color: #000;
  width: 120px !important;
  height: 120px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: -90px;
  margin-left: 0px;
}

.main_booking_form_container .logo-background {
  background-color: #e12e2a;
  width: 110px;
  height: 110px;
  border-radius: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  /* margin-top: -0px; */
}

.main_booking_form_container .logo {
  width: 100px;
  height: 100px;
  background-color: #000;
  margin-bottom: 10px;
  border-radius: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_booking_form_container .logo img {
  width: 87px;
  height: 50px;
}

.main_booking_form_container input#session-date {
  position: relative;
  overflow: hidden;
}

.main_booking_form_container
  input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(32);
}

.main_booking_form_container input#session-time {
  position: relative;
  overflow: hidden;
}

.main_booking_form_container
  input[type="time"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(32);
}

.booking_details_box_container .back-btn {
  cursor: pointer;
  margin-top: 30px;
  width: 132px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.booking_details_box_container .back-btn p {
  font-size: 14px;
  font-family: 'PoppinsLight';
  line-height: 20px;
  color: #000;
}

.booking_details_box_container .heading {
  margin-top: 20px;
  font-size: 32px;
  font-family: "PoppinsBold";
  line-height: 27px;
  color: #000;
}

.booking_details_box_container .address_details {
  margin-top: 30px;
}

.booking_details_box_container .address_box {
  display: flex;
  justify-content: start;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
}

.booking_details_box_container .address_box .text_from {
  font-size: 20px;
  font-family: "PoppinsLight";
  line-height: 27px;
  color: #000;
}

.booking_details_box_container .address_box img {
  padding-left: 20px;
}

.booking_details_box_container .address_box .text_to {
  font-size: 20px;
  font-family: "PoppinsLight";
  line-height: 27px;
  color: #000;
  padding-left: 20px;
}

.booking_details_box_container .address_box2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
}

.booking_details_box_container .address_box2 .text_from {
  font-size: 20px;
  font-family: "PoppinsLight";
  line-height: 27px;
  color: #000;
}

.booking_details_box_container .address_box2 .text_to {
  font-size: 20px;
  font-family: "PoppinsLight";
  line-height: 27px;
  color: #000;
  padding-left: 20px;
}

.booking_details_box_container .map-container {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 50px;
}

.booking_details_box_container .map-container .google-map {
  width: 100%;
  height: auto;
}

.booking_details_box_container .map-container .map_details {
  padding: 30px 0 0 30px;
}

.booking_details_box_container .map-container .map_details .dots {
  padding-left: 13px;
}

.booking_details_box_container .map-container .map_details .address {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  padding-top: 10px;
}

.booking_details_box_container .map-container .map_details .address p {
  font-size: 20px;
  font-family: "PoppinsLight";
  line-height: 27px;
  color: #8b8888;
}

.booking_details_box_container .booking_cars_box .passengers-price .car-type {
  font-size: 20px;
  font-family: "PoppinsLight";
  color: #000;
  line-height: 27px;
}

.booking_details_box_container
  .booking_cars_box
  .passengers-price
  .car-passengers {
  font-size: 20px;
  font-family: "PoppinsLight";
  color: #000;
  line-height: 27px;
}

.booking_details_box_container .booking_cars_box .passengers-price .car-color {
  font-size: 20px;
  font-family: "PoppinsBold";
  color: #000;
  line-height: 27px;
}

.booking_details_box_container .booking_cars_box .passengers-price .car-fare {
  font-size: 20px;
  font-family: "PoppinsBold";
  color: #000;
  line-height: 27px;
}

.booking_details_box_container .booking_cars_box .example-text {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 27px;
  color: #8a8a8a;
}

.booking_details_box_container .booking_cars_box .heading_type {
  font-size: 24px;
  font-family: 'PoppinsBold';
  line-height: 27px;
  color: #e12e2a;
  margin-top: 30px;
}

.booking_details_box_container .booking_cars_box .luggage-passesnger {
  display: flex;
  justify-content: start;
  align-items: center;
}

.booking_details_box_container
  .booking_cars_box
  .luggage-passesnger
  .passenger {
  border-right: 1px solid #cccccc;
}

.booking_details_box_container
  .booking_cars_box
  .luggage-passesnger
  .passenger
  span {
  font-size: 20px;
  font-family: "PoppinsLight";
  line-height: 27px;
  color: #000;
  padding-left: 5px;
  padding-right: 40px;
}

.booking_details_box_container .booking_cars_box .luggage-passesnger .luggage {
  padding-left: 10px;
}

.booking_details_box_container
  .booking_cars_box
  .luggage-passesnger
  .luggage
  span {
  font-size: 20px;
  font-family: "PoppinsLight";
  line-height: 27px;
  color: #000;
  padding-left: 5px;
}

.booking_details_box_container .booking_information .header {
  border-bottom: 1px solid #d6d6d6;
}

.booking_details_box_container .booking_information .header .heading {
  font-size: 20px;
  font-family: "PoppinsBold";
  line-height: 27px;
  color: #888;
}

.booking_details_box_container .booking_information .header .subheading {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #888;
}

.booking_details_box_container .booking_information .work_box {
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid #d6d6d6;
  margin-top: 20px;
}

.booking_details_box_container .booking_information .work_box .work_details {
  padding-left: 20px;
}

.booking_details_box_container
  .booking_information
  .work_box
  .work_details
  .text1 {
  font-size: 20px;
  font-family: "PoppinsBold";
  line-height: 27px;
  color: #888;
  margin: 0;
}

.booking_details_box_container
  .booking_information
  .work_box
  .work_details
  .text2 {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #888;
  margin: 5px 0 10px 0;
}

.booking_details_box_container .booking_information .work_box_last {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 20px;
}

.booking_details_box_container
  .booking_information
  .work_box_last
  .work_details {
  padding-left: 20px;
}

.booking_details_box_container
  .booking_information
  .work_box_last
  .work_details
  .text1 {
  font-size: 20px;
  font-family: "PoppinsBold";
  line-height: 27px;
  color: #888;
  margin: 0;
}

.booking_details_box_container
  .booking_information
  .work_box_last
  .work_details
  .text2 {
  font-size: 16px;
  font-family: "PoppinsLight";
  line-height: 24px;
  color: #888;
  margin: 5px 0 0 0;
}

@media screen and (max-width: 600px) {
  .main_booking_form_container {
    background-color: #e12e2a;
    width: 100%;
    padding-top: 60px;
  }

  .booking_form_inputs {
    width: 100%;
  }

  .booking_form_inputs label {
    display: flex;
    align-items: center;
  }

  .booking_form_inputs .second {
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .booking_form_inputs .different {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-radius: 7px;
  }

  .booking_form_inputs .different2 {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-radius: 7px;
    width: 160px;
  }

  .booking_form_inputs .input span {
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
    font-size: small;
  }

  .booking_form_inputs .input .input_field {
    height: 100%;
    width: 80%;
    margin-top: 20px;
  }

  .booking_form_inputs .input .input_field1 {
    width: 100%;
    position: relative;
  }

  .booking_form_inputs .input .input_field3 {
    width: 200px;
    position: relative;
  }

  .booking_form_inputs .input .input_field2 {
    width: 100%;
    position: relative;
  }

  .booking_form_inputs .input input {
    padding: 5px 5px;
    width: 70px;
    border: none;
    font-size: 10px;
    height: 20px;
    padding-bottom: 0px;
    background-color: transparent;
    color: #fff;
  }

  .booking_form_inputs .input input::placeholder {
    color: #fff;
  }

  .main_booking_form_container .underline {
    position: absolute;
    bottom: 0;
    left: 39px;
    width: 70px;
    height: 2px;
    background-color: #fff;
  }

  .booking_form_inputs .input input:focus-visible {
    outline: 0;
  }

  .booking_form_inputs button {
    width: 65px;
    height: 30px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.25);
    margin: 0px 20px;
    color: #fff;
    /* border: solid rgba(255, 255, 255, 1); */
    border: none;
    border-width: 1px;
    margin-right: 0px;
    margin-top: 0px;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .booking_form_inputs input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: no-repeat;
    border-width: thin;
  }

  .booking_form_inputs input[type="time"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: no-repeat;
    border-width: thin;
  }

  .booking_form_inputs .input img {
    padding-left: 15px;
    padding-right: 3px;
  }

  .booking_form_inputs .input select {
    width: 70px;
    height: 20px;
    border: none;
    padding-bottom: 0px;
    margin: 0px;
    padding: 0px 0px 0px 9px;
    border: none;
    color: #fff;
    font-size: 10px;
    margin-top: 5px;
    /* margin-top: 7px; */
    /* background-color: #FFF; */
  }

  .booking_form_inputs .input select option {
    color: #000;
  }

  .main_booking_form_container .logo-container {
    background-color: transparent;
    width: 70px;
    height: 20px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: center;
    align-content: center;
    /* margin-top: -70px; */
  }

  .main_booking_form_container .hhh {
    background-color: #000;
    width: 60px !important;
    height: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: -40px;
    margin-left: 0px;
  }

  .main_booking_form_container .logo-background {
    background-color: #e12e2a;
    width: 55px;
    height: 55px;
    border-radius: 27.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    /* margin-top: -0px; */
  }

  .main_booking_form_container .logo {
    width: 50px;
    height: 50px;
    background-color: #000;
    margin-bottom: 10px;
    border-radius: 25px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main_booking_form_container .logo img {
    width: 40px;
    height: 24px;
  }

  .main_booking_form_container input#session-date {
    position: relative;
    overflow: hidden;
  }

  .main_booking_form_container
    input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    left: 0;
    background: #0000;
    position: absolute;
    transform: scale(32);
  }

  .main_booking_form_container input#session-time {
    position: relative;
    overflow: hidden;
  }

  .main_booking_form_container
    input[type="time"]::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    left: 0;
    background: #0000;
    position: absolute;
    transform: scale(32);
  }

.booking_details_box_container .booking_information .header .heading {
  font-size: 14px;
}

.booking_details_box_container .booking_cars_box .heading_type {
  font-size: 14px;
  line-height: 17px;
}

.booking_details_box_container .booking_information .header .subheading {
  font-size: 12px;
}

.booking_details_box_container .booking_information .work_box img {
  width: 24px;
  height: auto;
}

.booking_details_box_container .booking_information .work_box .work_details .text1 {
  font-size: 12px;
}

.booking_details_box_container .booking_information .work_box .work_details .text2 {
  font-size: 12px;
}

.booking_details_box_container .booking_information .work_box_last img {
  width: 24px;
  height: auto;
}

.booking_details_box_container .booking_information .work_box_last .work_details .text1 {
  font-size: 12px;
}

.booking_details_box_container .booking_information .work_box_last .work_details .text2 {
  font-size: 12px;
}

  .booking_details_box_container .address_box .text_from {
    font-size: 12px;
  }

  .booking_details_box_container .address_box .text_to {
    font-size: 12px;
  }

  .booking_details_box_container .address_box2 .text_from {
    font-size: 12px;
  }

  .booking_details_box_container .address_box2 .text_to {
    font-size: 12px;
  }

  .booking_details_box_container .map-container {
    margin-bottom: 40px;
  }

  .booking_details_box_container .booking_cars_box .passengers-price .car-type {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .booking_details_box_container
    .booking_cars_box
    .passengers-price
    .car-passengers {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .booking_details_box_container
    .booking_cars_box
    .passengers-price
    .car-color {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .booking_details_box_container .booking_cars_box .passengers-price .car-fare {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .booking_details_box_container .booking_cars_box .example-text {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .booking_details_box_container .booking_cars_box .luggage-passesnger .passenger img {
    width: 16px;
    height: 16px;
  }

  .booking_details_box_container .booking_cars_box .luggage-passesnger .passenger span {
    font-size: 12px;
  }

  .booking_details_box_container .booking_cars_box .luggage-passesnger .luggage img {
    width: 16px;
    height: 16px;
  }

  .booking_details_box_container .booking_cars_box .luggage-passesnger .luggage span {
    font-size: 12px;
  }

    .booking_details_box_container .map-container .map_details .address p {
      font-size: 12px;
    }

  .booking_details_box_container .booking_information .work_box_last {
    margin-bottom: 80px;
  }
}
