.luxurious_main_container {
  margin-top: 120px;
  margin-bottom: 100px;
}

.luxurious_main_container .header_box h1 {
  text-align: center;
  font-size: 45px;
  font-family: 'PoppinsRegular';
  line-height: 40px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.luxurious_main_container .header_box h1 span {
  color: #e12e2a;
}

.luxurious_main_container .header_box p {
  color: #000;
  text-align: center;
  font-family: "PoppinsLight";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.32px;
}

.luxurious_main_container .luxurious_box {
  margin-top: 50px;
  text-align: center;
}

.luxurious_main_container .luxurious_box .luxuries_inner_box .luxury_img {
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.luxurious_main_container .luxurious_box .luxuries_inner_box .luxury_text {
  text-align: left;
  padding-left: 20px;
  font-size: 20px;
  font-family: "PoppinsBold";
  line-height: 24px;
  margin-top: 10px;
}

.luxurious_main_container .luxurious_box .luxury_btn {
  display: inline-block;
  margin-top: 40px;
}

.luxurious_main_container .luxurious_box .luxury_btn .btn_inner {
  cursor: pointer;
  background-color: #e12e2a;
  padding: 10px 50px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-family: 'PoppinsRegular';
  line-height: 20px;
  transition: all 0.5s;
}

.luxurious_main_container .luxurious_box .luxury_btn .btn_inner:hover {
  background: #000;
}

@media screen and (max-width: 1024px) {
  .luxurious_main_container .header_box h1 {
    font-size: 24px;
    line-height: 25px;
    letter-spacing: 0px;
  }

  .luxurious_main_container .header_box p {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.33px;
  }

  .luxurious_main_container .luxurious_box .luxury_btn .btn_inner {
    padding: 10px 30px;
  }
  .luxurious_main_container .luxurious_box .luxuries_inner_box .luxury_text {
    font-size: 14px;
    line-height: 18px; 
  }
}
