.main_body_container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.main_body_container .image-box {
  position: relative;
}

.main_body_container .image-container {
  position: relative;
  z-index: 9999;  
}

.main_body_container .image-box .image-effect {
  position: absolute;
  top: -80px;
  left: -80px;
}


.main_body_container .image-container img {
  width: 100%;
  border-radius: 30px;
}

.main_body_container .content-container h1 .headingpart1 {
  color: #000;
  font-size: 45px;
  font-family: 'PoppinsRegular';
  line-height: 45px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.main_body_container .content-container h1 .headingpart2 {
  color: #E12E2A;
  font-size: 45px;
  font-family: 'PoppinsRegular';
  line-height: 45px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.main_body_container .content-container p {
  flex-shrink: 0;
  color: #000;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  font-family: 'PoppinsLight';
  line-height: 30px;
  letter-spacing: 0.32px;
}

.main_body_container .grid-item {
  cursor: pointer;
  width: 100%;
  height: 90%;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #EDEDED;
  background: #FFF;
  padding: 10px 0px 10px 30px;
}

.main_body_container .grid-item p {
  width: 277.866px;
  height: 108.263px;
  flex-shrink: 0;
  color: #626262;
  font-family: 'PoppinsLight';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.28px;
}

.main_body_container .grid-item .header {
  display: flex;
  justify-content: space-between;
  margin: 0px;
}

.main_body_container .grid-item .header h3 {
  color: #000;
  font-size: 18px;
  font-family: 'PoppinsRegular';
}

.main_body_container .grid-item .header img {
  width: 60px;
  height: 50px;
  margin-left: -2px;
}

.main_body_container .grid-item .header div {
  background-color: #000;
  width: 55px;
  height: 47px;
  border-radius: 0px 10px 0px 25px;
  transition: all 0.5s;
  cursor: pointer;
}

.main_body_container .grid-item .header div:hover {
  background-color: #E12E2A;
}

@media screen and (max-width: 1024px) {

  .main_body_container {
    margin-top: 100px;
  }

  .main_body_container .image-container img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 50px;
  }

  .main_body_container .content-container h1 .headingpart1 {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
  }

  .main_body_container .content-container h1 .headingpart2 {
    color: #E12E2A;
    /* font-family: Atlan; */
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
  }

  .main_body_container .grid-item {
    height: 100%;
    border-radius: 10px;
    border: 1px solid #EDEDED;
    background: #FFF;
    padding: 0px 5px 5px 10px;
    margin-top: 20px;
  }

  .main_body_container .grid-item p {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    color: #626262;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    /* 185.714% */
    letter-spacing: 0.28px;
  }

  .main_body_container .grid-item .header {
    display: flex;
    justify-content: space-between;
    margin: 0px;
  }

  .main_body_container .grid-item .header h3 {
    color: #000;
    margin-top: 5px;
  }

  .main_body_container .grid-item .header img {
    width: 30px;
    height: 25px;
    margin-left: -1px;
  }

  .main_body_container .grid-item .header div {
    background-color: #000;
    width: 28px;
    height: 24px;
    border-radius: 0px 5px 0px 10px;
  }

  .main_body_container .grid-item .header div:hover {
    background-color: #E12E2A;
  }
}