.main_about_us_container .left_container {
    /* background: rgba(0, 0, 0, 0.81); */
    margin: 0%;
    margin-top: -5px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.main_about_us_container .left_container h1 {
    font-family: 'PoppinsRegular';
    font-size: 40px;
    line-height: 40px;
    /* 100% */
    letter-spacing: 0.9px;
    text-transform: uppercase;
    margin: 5px 0;
}

.main_about_us_container .left_container .upper_heading {
    padding-top: 70px;
}

.main_about_us_container .left_container .red {
    font-size: 22px;
    font-family: 'PoppinsLight';
    line-height: normal;
    margin: 30px 0;
}

.main_about_us_container .red1{
    color: var(--Red-color, #E12E2A);
}

.main_about_us_container .left_container .text {
    font-family: 'PoppinsLight';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    /* 187.5% */
    letter-spacing: 0.32px;
    margin: 40px 0;
    text-align: center;
}

.main_about_us_container .about_img {
    width: 100%;
    height: auto;
}